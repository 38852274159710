<template>
    <div class="cert-group">

        <div class="page-header">
            <el-row v-if="this.$store.getters.isManagerAuth">
                <el-col :span="24" align="right">
                    <el-button type="primary" round icon="el-icon-my-user" @click="handleEdie()">{{ $t('data.group.createNewGroup') }}</el-button>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">
            <el-col :span="24">
                <el-card shadow="always">
                    <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                            align="center"
                            prop="number"
                            :label="$t('data.group.table.groupNumber')"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            :label="$t('data.group.table.groupName')"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="created_at"
                            :label="$t('data.group.table.createDate')">
                        </el-table-column>
                        <el-table-column
                            prop="branch.name"
                            :label="$t('data.group.table.campusBranch')">
                        </el-table-column>
                        <el-table-column
                            prop="creator.name"
                            :label="$t('data.group.table.handleAccount')">
                        </el-table-column>
                        <el-table-column
                            prop="creator.name"
                            :label="$t('data.group.table.name')">
                        </el-table-column>
                        <el-table-column
                            prop="designs"
                            :label="$t('data.group.table.designs')">
                        </el-table-column>
                        <el-table-column
                            prop="cert_count"
                            :label="$t('data.group.table.approvedCerts')">
                        </el-table-column>
                        <el-table-column
                             v-if="this.$store.getters.isManagerAuth"
                            align="right"
                            :label="$t('data.group.table.action')"
                            width="190">
                            <template #default="scope">
                                <el-button @click="handleEdie(scope.row)" type="primary" size="small">Edit</el-button>
                                <el-popconfirm
                                @confirm="handleDelete(scope.row, scope.$index)"
                                confirm-button-type="danger"
                                :title="$t('data.group.isDeleteText')+'？'">
                                    <template #reference>
                                        <el-button type="danger" size="small">Delete</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>

                    <table-pagination :page="page" @updatePage="updatePage"></table-pagination>

                </el-card>
            </el-col>
        </el-row>

        <grouo-dialog :groupData="groupData"></grouo-dialog>
    </div>
</template>

  <script>
    import tablePagination from "@/components/tablePagination/TablePagination"
    import grouoDialog from "./component/groupDialog.vue"
    import { ElMessage } from 'element-plus'
    import { certGroups, certGroupsDel } from '../../api/index'
    export default {
      name: "cert_group",
      components:{
        tablePagination,
        grouoDialog
      },
      data() {
        return {
          tableData: [],
          page: {},
          groupData: {
              state: false
          }
        }
      },
      mounted(){
        this.initPageData();
      },
      methods: {
        initPageData(data){
          let apiData = {}
          if(data){
            Object.assign(apiData,data)
          }
           certGroups({apiData}).then( res => {
             this.tableData = res.data;
             this.page = res.meta.page;
          }) 
        },
        handleEdie(row) {
            if(row){
                this.groupData.state = true;
                this.groupData.id = row.id;
                this.groupData.name = row.name;
                this.groupData.number = row.number;
                this.groupData.saveType = 'edie'
            }else{
                this.groupData.name = '';
                this.groupData.number = '';
                this.groupData.state = true;
                this.groupData.saveType = 'create'
            }
        },
        handleDelete(row,index){
          let apiData = {id: row.id}
           certGroupsDel({apiData}).then( res => {
              ElMessage.success({
                  message: 'success',
                  type: 'success'
              });
              this.initPageData();
          })
            
        },
        submitCertGroup(){
            ElMessage.success({
                message: '修改成功',
                type: 'success'
            });
            this.dialogFormVisible = false;
        },
        updatePage(data){
            this.initPageData(data)
        }
       }
    }
  </script>
